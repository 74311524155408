<template>
    <div class="quickCheckSetting-table-gbpop">
        <div class="btn-con">
            <span class="btn" @click="choseGB">确定</span>
        </div>
        <div class="table-con">
            <el-table
                :data="tableData"
                height="100%"
                border
                style="width: 100%"
                stripe
                ref="multipleTable"
                @row-click="rowClick"
            >
                <el-table-column
                    key="select"
                    type="selection"
                    width="55"
                >
                </el-table-column>
                <el-table-column
                    prop="item_name"
                    label="检测项目"
                >
                    <template slot-scope="scope">
                        <span>{{ scope.row.item_name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="gb_value"
                    label="国标指标"
                    width="230px"
                >
                    <template slot-scope="scope">
                        {{ getItemStr(scope.row.gb_index) }}
                    </template>
                </el-table-column>
                <el-table-column
                    prop="remarks"
                    label="备注"
                >
                </el-table-column>
            </el-table>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        extr: {
            type: [Object],
        },
    },
    data() {
        return {
            tableData: [],
            tableSelectData: [], // 单选
        };
    },
    watch: {},
    computed: {},
    methods: {
        // 获取国标数据
        getGBData() {
            this.$axios
                .get(`/interfaceApi/procurement/quality/config/batchtesting/indexset/selectgb?type_dic_code=${this.extr.currentData.type_dic_code}`
                    + `&child_type_code=${this.extr.currentData.child_type_code}&child_type_dic_code=${this.extr.currentData.child_type_dic_code}`
                    + `&spec_code=${this.extr.currentData.spec_code}&spec_dic_code=${this.extr.currentData.spec_dic_code}`)
                .then(res => {
                    if (res) {
                        const codes = [];
                        this.extr.tableData.forEach(item => {
                            codes.push(item.item_code);
                        });
                        this.tableData = res.filter(item => {
                            return codes.indexOf(item.item_code) === -1;
                        });
                    }
                })
                .catch(error => {
                    this.$message.error(error.ErrorCode.Message);
                });
        },
        rowClick(row) {
            this.$refs.multipleTable.toggleRowSelection(row);
            this.tableSelectData = this.$refs.multipleTable.selection;
        },
        // 确定选择
        choseGB() {
            this.tableSelectData = this.$refs.multipleTable.selection;
            if (this.tableSelectData.length > 0) {
                this.extr.callBack(this.tableSelectData);
            } else {
                this.$message.warning('请选择数据');
            }
        },
        // 获取指标字符串
        getItemStr(str) {
            return (str || '').replace(/\|/g, '');
        },
    },
    created() {
        this.getGBData();
    },
    mounted() {

    },
};
</script>
<style lang="stylus">
.quickCheckSetting-table-gbpop
    float left
    width calc(100% - .2rem)
    margin .1rem
    height calc(100% - .2rem)
    background #fff
    padding .1rem
    .btn-con
        .btn
            padding .05rem .1rem
            text-align center
            background #1577D2
            border-radius .04rem
            display inline-block
            color #ffffff
            cursor pointer
            margin-bottom .1rem
    .table-con
        height calc(100% - .4rem)
        .iconshanchu
            color #D5000F
            cursor pointer
</style>
<style>
.quickCheckSetting-table-gbpop .el-table--border td, .el-table--border th, .el-table__body-wrapper
.el-table--border.is-scrolling-left~.el-table__fixed{
    border-right: 0;
}
.quickCheckSetting-table-gbpop .el-table td{
    border-bottom: 0;
    text-align: center;
}
.quickCheckSetting-table-gbpop .el-table td, .el-table th{
    padding: .08rem 0;
}
.quickCheckSetting-table-gbpop .el-table thead th{
    background: #EDF0F5;
    color: #022782;
    text-align: center;
    padding: 0.06rem;
    height: 0.36rem;
}
.quickCheckSetting-table-gbpop .el-table--striped .el-table__body tr.el-table__row--striped td{
    background: #F7FBFF;
}
.quickCheckSetting-table-gbpop .cell>.el-select{
    width: 1rem;
    display: inline-block;
    margin-right: .05rem;
}
.quickCheckSetting-table-gbpop .cell>.el-input{
    width: 1rem;
    display: inline-block;
}
</style>
